import React from 'react'
import confetti1 from './confetti1.svg'
import confetti2 from './confetti2.svg'
import confetti3 from './confetti3.svg'
import confetti4 from './confetti4.svg'
import confetti5 from './confetti5.svg'
import confetti6 from './confetti6.svg'
import confetti7 from './confetti7.svg'
import confetti8 from './confetti8.svg'
import Scatter from '../Scatter'

const Confetti = ({ ...props }) => (
  <Scatter
    {...props}
    coverage={1.2}
    minimumStageScale={1}
    shapes={[
      { url: confetti1, weight: 4 },
      { url: confetti2, weight: 1 },
      { url: confetti3, weight: 2 },
      { url: confetti4, weight: 1 },
      { url: confetti5, weight: 1 },
      { url: confetti6, weight: 1 },
      { url: confetti7, weight: 1 },
      { url: confetti8, weight: 3 }
    ]}
  />
)

export default Confetti
