import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getAnswerPercent } from '../selectors'
import OutlinedText from './OutlinedText'

const Percent = ({ classes, percent }) => (
  <div className={classes.container}>
    <h2 className={classes.text}>
      <OutlinedText>
        <span className={classes.number}>{percent}</span>
        <span className={classes.percentSign}>%</span>
      </OutlinedText>
    </h2>
  </div>
)

const mapStateToProps = (state) => ({
  percent: getAnswerPercent(state)
})

export default compose(
  connect(mapStateToProps),

  injectSheet({
    container: {
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      color: '#e319c5',
      fontSize: '25vmin',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'none'
    },
    text: {
      margin: 'auto',
      position: 'relative'
    },
    percentSign: {
      fontSize: '0.6em',
      position: 'relative',
      top: '-0.45em'
    }
  })
)(Percent)
