import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getAnswerIndex, getAnswerStyles, getQuestionIndex } from './display'

export const getQuestionCount = state => state.content.questions.length
export const getQuestions = state => state.content.questions
const getQuestionRawAnswers = state => getQuestions(state)[getQuestionIndex(state)]
export const getQuestionAnswers = createSelector(
  getQuestionRawAnswers,
  getAnswerStyles,
  (answers, styles) => answers.map((answer, i) => ({
    text: answer,
    styles: styles[i]
  }))
)
export const getStatistics = state => state.content.statistics
export const getAnswerStatistics = state => getStatistics(state)[getQuestionIndex(state)]
export const getAnswerPercent = createSelector(
  getAnswerIndex,
  getAnswerStatistics,
  (answerIndex, counts) => {
    const chosenAnswerCount = counts[answerIndex]
    const otherAnswerCount = counts[(answerIndex + 1) % 2]
    return Math.round(100 * chosenAnswerCount / (chosenAnswerCount + otherAnswerCount))
  }
)
export const isContentReady = state => !!(
  get(state.content, ['questions', 'length']) &&
  get(state.content, ['statistics', 'length'])
)
