import { ANSWER_SELECTED, CONTENT_FULFILLED } from '../actions'

const initialState = {
  questions: [],
  statistics: []
}

const content = (state = initialState, action) => {
  switch (action.type) {
    case ANSWER_SELECTED:
      const { questionIndex, answerIndex } = action.payload
      const statistics = [...state.statistics]
      statistics[questionIndex][answerIndex] += 1
      return {
        ...state,
        statistics
      }

    case CONTENT_FULFILLED:
      return {
        ...state,
        ...action.payload
      }

    default:
      return state
  }
}
export default content
