import React, { PureComponent } from 'react'
import elementResizeDetectorMaker from 'element-resize-detector'
import offset from 'dom-helpers/query/offset'
import * as PIXI from 'pixi.js'

class PatternBackground extends PureComponent {
  state = {
    width: 0,
    height: 0
  }

  setRef = el => {
    if (el) {
      const erd = elementResizeDetectorMaker({ strategy: 'scroll' })

      erd.listenTo(el, () => {
        const { width, height } = offset(el)
        if (this.pixi) {
          this.pixi.renderer.resize(width, height)
        } else {
          const { backgroundColor } = this.props
          this.pixi = new PIXI.Application({
            width,
            height,
            backgroundColor,
            antialias: true,
            autoResize: true,
            forceCanvas: true, // so we can use HARD_LIGHT and DARKEN blend modes in QuestionTitle
            resolution: window.devicePixelRatio || 1,
            sharedTicker: true
          })
          el.appendChild(this.pixi.view)
        }
        this.setState({ width, height })
      })

      this.unbindElementResizeDetector = () => {
        erd.removeAllListeners(el)
      }
    } else {
      this.unbindElementResizeDetector()
      this.pixi.destroy(true)
    }
  }

  componentDidUpdate (prevProps) {
    const { backgroundColor } = this.props
    if (backgroundColor !== prevProps.backgroundColor) {
      this.pixi.renderer.backgroundColor = backgroundColor
    }
  }

  render () {
    const { children, className, Pattern, backgroundColor } = this.props
    const { width, height } = this.state

    let pattern
    const { pixi } = this
    // note: passing width and height down to ensure children update on resize
    const childProps = { width, height, pixi }
    if (pixi && Pattern) {
      pattern = <Pattern {...childProps} />
    }

    const style = {}
    if (backgroundColor) {
      style.backgroundColor = PIXI.utils.hex2string(backgroundColor)
    }

    return (
      <div className={className} ref={this.setRef} style={style}>
        {pattern}
        {pixi && React.Children.map(children, child => React.cloneElement(child, childProps))}
      </div>
    )
  }
}

export default PatternBackground
