import shuffle from 'lodash/shuffle'
import range from 'lodash/range'
import {
  ANSWER_SELECTED,
  CONTENT_FULFILLED,
  fontsReadyCreator,
  fullscreenChangedCreator,
  NEXT_QUESTION,
  nextQuestionCreator,
  QUESTION_INDEX_CHANGED,
  questionIndexChangedCreator,
  readyToAnswerCreator,
  REQUEST_FULLSCREEN,
  stylesChangedCreator
} from '../actions'
import { getQuestionCount, getQuestionIndex, isFullScreen } from '../selectors'
import screenfull from 'screenfull'
import WebFont from 'webfontloader'

export default store => {
  if (screenfull.enabled) {
    screenfull.on('change', () => {
      const state = store.getState()
      if (isFullScreen(state) !== screenfull.isFullscreen) {
        store.dispatch(fullscreenChangedCreator(screenfull.isFullscreen))
      }
    })
  }
  const fontsReady = () => {
    store.dispatch(fontsReadyCreator())
  }
  WebFont.load({
    active: fontsReady,
    inactive: fontsReady,
    classes: false,
    custom: {
      families: ['Komika Axis', 'Righteous'],
      testStrings: {
        Righteous: 'YOUDECI'
      }
    }
  })

  const startPreQuestionTimer = () => {
    const fgs = shuffle(range(1, 3))
    const bgs = shuffle(range(1, 5))
    const patterns = shuffle(range(1, 5))
    store.dispatch(stylesChangedCreator([
      { bg: bgs[0], pattern: patterns[0], fg: fgs[0] },
      { bg: bgs[1], pattern: patterns[1], fg: fgs[1] }
    ]))
    setTimeout(() => {
      store.dispatch(readyToAnswerCreator())
    }, 4000)
  }
  return next => action => {
    const state = store.getState()
    const questionIndex = getQuestionIndex(state)
    switch (action.type) {
      case NEXT_QUESTION:
        const questionCount = getQuestionCount(state)
        action = questionIndexChangedCreator((questionIndex + 1) % questionCount)
        // fall through
      case QUESTION_INDEX_CHANGED:
        startPreQuestionTimer()
        break
      case CONTENT_FULFILLED:
        startPreQuestionTimer()
        break
      case ANSWER_SELECTED:
        action.payload.questionIndex = questionIndex
        setTimeout(() => {
          store.dispatch(nextQuestionCreator())
        }, 3000)
        break
      case REQUEST_FULLSCREEN:
        if (screenfull.enabled) {
          screenfull.request()
        }
      // no default
    }

    return next(action)
  }
}
