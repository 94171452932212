import { PureComponent } from 'react'
import * as PIXI from 'pixi.js'
import TweenMax from 'gsap/TweenMaxBase'
import { SteppedEase } from 'gsap/EasePack'
import cloneDeep from 'lodash/cloneDeep'
import shuffle from 'lodash/shuffle'

const availableColors = [
  [0xfff790, 1],
  [0xb91b8d, 1],
  [0x2a3d99, 1],
  [0x191654, 1],
  [0x05033a, 1],
  [0x000015, 1]
]

const FRAMES = 2

const designWidth = 1280
const designHeight = 720
const designAspect = designWidth / designHeight

function drawRect (graphics, color) {
  const [rgb, alpha] = color
  return (
    graphics
      .clear()
      .beginFill(rgb, alpha)
      .drawRect(0, 0, 10, 10)
      .endFill()
  )
}

class Pixels extends PureComponent {
  _frame = 0
  scene = new PIXI.Container()

  get frame () {
    return this._frame
  }

  set frame (frame) {
    this._frame = Math.round(frame)
    const { pixels, scene } = this
    scene.children.forEach((rect, i) => {
      const frames = pixels[i]
      drawRect(rect, frames[frame])
    })
  }

  componentDidMount () {
    this.tween = TweenMax.to(this, 0.7, {
      frame: FRAMES - 1,
      ease: SteppedEase.config(FRAMES - 1),
      repeat: -1,
      id: 'Pixels'
    })
    const { pixi } = this.props
    const { scene } = this
    pixi.stage.addChild(scene)
  }

  componentWillUnmount () {
    this.tween.kill()
    const { pixi } = this.props
    const { scene } = this
    if (pixi && pixi.stage) {
      pixi.stage.removeChild(scene)
    }
  }

  render () {
    const { pixi } = this.props
    const { width, height } = pixi.screen
    const { scene } = this
    scene.removeChildren()
    this.pixels = []

    const aspect = width / height
    const worldWidth = designAspect > aspect ? designHeight * aspect : designWidth
    const worldHeight = designAspect > aspect ? designHeight : designWidth / aspect
    scene.scale = new PIXI.Point(width / worldWidth, height / worldHeight)

    let col = 0
    let y
    const cols = worldWidth / 80
    while (col < cols) {
      y = (col * 15) % 36 - 5
      while (y < worldHeight) {
        const frameColors = []
        for (let i = 0; i < FRAMES; ++i) {
          const colors = shuffle(cloneDeep(availableColors))
          for (let j = 0; j < colors.length; ++j) {
            if (Math.random() > 0.9) {
              colors[j][1] = 0
            }
          }
          frameColors.push(colors)
        }
        let x = col * 80 + 15
        for (let i = 0; i < availableColors.length; ++i) {
          this.pixels.push(frameColors.map(frame => frame[i]))
          const rect = new PIXI.Graphics()
          rect.x = x
          rect.y = y
          scene.addChild(rect)
          x += 10
        }
        y += 36
      }
      col += 1
    }

    return null
  }
}

export default Pixels
