import React from 'react'
import injectSheet from 'react-jss'
import cn from 'classnames'

const Loading = ({ classes, className }) => (
  <div className={cn(className, classes.loading)}>
    <div className={classes.blip} />
    <div className={classes.blip} />
    <div className={classes.blip} />
    <div className={classes.blip} />
  </div>
)

export default injectSheet({
  loading: {
    display: 'inline-block',
    position: 'relative',
    width: '1em',
    height: '1em'
  },
  blip: {
    position: 'absolute',
    top: '0.422em',
    width: '0.172em',
    height: '0.172em',
    borderRadius: '50%',
    background: '#fff',
    animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
    '&:nth-child(1)': {
      left: '0.0938em',
      animation: 'blip-1 0.6s infinite'
    },
    '&:nth-child(2)': {
      left: '0.0938em',
      animation: 'blip-2 0.6s infinite'
    },
    '&:nth-child(3)': {
      left: '0.406em',
      animation: 'blip-2 0.6s infinite'
    },
    '&:nth-child(4)': {
      left: '0.703em',
      animation: 'blip-3 0.6s infinite'
    }
  },

  '@keyframes blip-1': {
    '0%': {
      transform: 'scale(0)'
    },
    '100%': {
      transform: 'scale(1)'
    }
  },
  '@keyframes blip-2': {
    '0%': {
      transform: 'translate(0, 0)'
    },
    '100%': {
      transform: 'translate(0.297em, 0)'
    }
  },
  '@keyframes blip-3': {
    '0%': {
      transform: 'scale(1)'
    },
    '100%': {
      transform: 'scale(0)'
    }
  }
})(Loading)
