import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as PIXI from 'pixi.js'
import { getRandomIntInclusive } from '../../helpers/getRandomIntInclusive'

const designWidth = 640
const designHeight = 720
const designAspect = designWidth / designHeight

const resolution = 3

function hitTestCircles (a, b, toleranceRatio = 0) {
  const aRadius = Math.sqrt(a.width ** 2 + a.height ** 2) / 2
  const bRadius = Math.sqrt(b.width ** 2 + b.height ** 2) / 2

  const distance = Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2)
  const touchingDistance = aRadius + bRadius
  const tolerance = toleranceRatio * touchingDistance
  return distance < (touchingDistance - tolerance)
}

class Scatter extends PureComponent {
  static propTypes = {
    avoidCollisions: PropTypes.bool,
    jitter: PropTypes.bool,
    minimumStageScale: PropTypes.number,
    positionVariation: PropTypes.number,
    spacingWeight: PropTypes.number,
    tolerance: PropTypes.number,
    shapes: PropTypes.arrayOf(PropTypes.shape({
      url: PropTypes.string.isRequired,
      weight: PropTypes.number
    }))
  }

  static defaultProps = {
    avoidCollisions: false,
    coverage: 1,
    minimumStageScale: 0,
    positionVariation: 1.2,
    jitter: false,
    spacingWeight: 1.2,
    tolerance: 0.5
  }

  constructor (props, context) {
    super(props, context)
    const { shapes } = props
    const textures = shapes.map(({ url }) => PIXI.Texture.fromImage(url, undefined, undefined, resolution))
    const ready = () => {
      const loaded = shapes.map((shape, i) => {
        const { scale = 1 } = shape
        const texture = textures[i]
        const width = scale * texture.width / resolution
        const height = scale * texture.height / resolution
        return {
          ...shape,
          texture,
          width,
          height,
          area: Math.PI * (Math.max(width, height) / 2) ** 2
        }
      })
      this.textures = loaded
      this.totalWeight = loaded.reduce((sum, { weight }) => sum + weight, 0)
      this.avgWidth = loaded.reduce((sum, { width }) => sum + width, 0) / loaded.length
      this.avgHeight = loaded.reduce((sum, { height }) => sum + height, 0) / loaded.length
      this.minWidth = loaded.reduce((min, { width }) => Math.min(min, width), Infinity)
      this.minHeight = loaded.reduce((min, { height }) => Math.min(min, height), Infinity)
      this.maxWidth = loaded.reduce((max, { width }) => Math.max(max, width), 0)
      this.maxHeight = loaded.reduce((max, { height }) => Math.max(max, height), 0)
    }

    const notYetLoaded = textures.filter(({ baseTexture }) => !baseTexture.hasLoaded)
    if (notYetLoaded.length) {
      let awaiting = notYetLoaded.length
      notYetLoaded.forEach(({ baseTexture }) => {
        baseTexture.once('loaded', () => {
          awaiting -= 1
          if (awaiting === 0) {
            ready()
            this.forceUpdate()
          }
        })
      })
    } else {
      ready()
    }
  }

  getWeightedRandomShape () {
    const t = Math.random() * this.totalWeight
    let cumulativeWeight = 0
    for (let i = 0; i < this.textures.length; ++i) {
      cumulativeWeight += this.textures[i].weight
      if (cumulativeWeight >= t) {
        return this.textures[i]
      }
    }
  }

  getWeightedRandomSprite (shape = this.getWeightedRandomShape()) {
    const { rotation, scale = 1, texture } = shape
    const sprite = new PIXI.Sprite(texture)
    sprite.anchor = new PIXI.Point(0.5, 0.5)
    const { tints } = this.props
    if (tints) {
      sprite.tint = tints[getRandomIntInclusive(0, tints.length - 1)]
    }
    sprite.scale = new PIXI.Point(scale / resolution, scale / resolution)
    sprite.rotation = Math.PI * 2 * (
      typeof rotation === 'undefined'
        ? Math.random()
        : rotation / 360
    )
    return sprite
  }

  scatterSpritesRandomly (worldWidth, worldHeight) {
    const { pixi, coverage: targetCoverage } = this.props
    const totalArea = worldWidth * worldHeight
    let coveredArea = 0
    while (coveredArea / totalArea < targetCoverage) {
      const shape = this.getWeightedRandomShape()
      const sprite = this.getWeightedRandomSprite(shape)
      const x = Math.random() * (worldWidth + sprite.width) - sprite.width / 2
      const y = Math.random() * (worldHeight + sprite.height) - sprite.height / 2
      sprite.x = x
      sprite.y = y
      pixi.stage.addChild(sprite)
      coveredArea += shape.area
    }
  }

  jitterSprites (worldWidth, worldHeight) {
    const { avgWidth, avgHeight, maxWidth, maxHeight } = this
    const { pixi, positionVariation, spacingWeight } = this.props
    let y = 0
    while (y < worldHeight + maxHeight) {
      let x = 0
      while (x < worldWidth + maxWidth) {
        const sprite = this.getWeightedRandomSprite()
        sprite.x = x + (Math.random() - 0.5) * sprite.width * positionVariation
        sprite.y = y + (Math.random() - 0.5) * sprite.height * positionVariation
        pixi.stage.addChild(sprite)

        x += avgWidth * spacingWeight
      }
      y += avgHeight * spacingWeight
    }
  }

  scatterSpritesAvoidingCollisions (worldWidth, worldHeight) {
    const { pixi, coverage: targetCoverage, tolerance } = this.props

    const maybePlaceSprite = (sprite, x, y, spacing) => {
      sprite.x = x
      sprite.y = y
      if (!pixi.stage.children.find(existing => hitTestCircles(sprite, existing, tolerance))) {
        pixi.stage.addChild(sprite)
        return true
      }
    }

    const totalArea = worldWidth * worldHeight
    let coveredArea = 0
    for (let i = 0; i < 5000; ++i) {
      const shape = this.getWeightedRandomShape()
      const sprite = this.getWeightedRandomSprite(shape)
      for (let j = 0; j < 100; ++j) {
        const x = Math.random() * (worldWidth + sprite.width) - sprite.width / 2
        const y = Math.random() * (worldHeight + sprite.height) - sprite.height / 2
        if (maybePlaceSprite(sprite, x, y)) {
          coveredArea += shape.area
          break
        }
      }
      if (coveredArea / totalArea > targetCoverage) {
        break
      }
    }
  }

  render () {
    if (!this.textures) {
      return null
    }

    const { pixi, avoidCollisions, jitter, minimumStageScale } = this.props
    const { width, height } = pixi.screen
    pixi.stage.removeChildren()

    const aspect = width / height
    const scaledWorldWidth = designAspect > aspect ? designHeight * aspect : designWidth
    const stageScale = Math.max(width / scaledWorldWidth, minimumStageScale)
    const worldWidth = width / stageScale
    const worldHeight = height / stageScale
    pixi.stage.scale = new PIXI.Point(stageScale, stageScale)

    if (jitter) {
      this.jitterSprites(worldWidth, worldHeight)
    } else if (avoidCollisions) {
      this.scatterSpritesAvoidingCollisions(worldWidth, worldHeight)
    } else {
      this.scatterSpritesRandomly(worldWidth, worldHeight)
    }

    return null
  }
}

export default Scatter
