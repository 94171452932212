import shuffle from 'lodash/shuffle'
import range from 'lodash/range'
import { contentActionCreator, QUESTION_INDEX_CHANGED } from '../actions'
import { getQuestions, getStatistics, isContentReady } from '../selectors'
import { getRandomIntInclusive } from '../helpers/getRandomIntInclusive'

const initialQuestions = [
  ['Iced Vo Vo', 'Icy Volvo'],
  ['Playing it cool', 'The cold shoulder'],
  ['Cat nap', 'Kidnap'],
  ['Clickety clack', 'Flip flop'],
  ['Dollar dazzler', 'Sausage sizzle'],
  ['Fast cars', 'Fast food'],
  ['Bushwalk', 'Bush doof'],
  ['The Muppets', 'The mafia'],
  ['Scat', 'Skate'],
  ['Death stare', 'Snake eyes'],
  ['Fish ’n’ chips', 'Snakes ’n’ ladders'],
  ['Stop, drop and roll', 'Duck ’n’ cover'],
  ['Sonic boom', 'Baby boomer'],
  ['Sundried whales', 'Shrimp paste'],
  ['The Godfather trilogy', 'Three Men And A Baby'],
  ['Sky\u00ADscrapers', 'Suburbia'],
  ['Trick', 'Treat'],
  ['Him', 'Me'],
  ['Ghost Busters', 'Inter\u200Bgalactic planetary alignment'],
  ['Invisible ink', 'Mind reader'],
  ['Party platter', 'Deluxe buffet'],
  ['Artificial rocks', 'Portable holes'],
  ['Oops', 'Ouch'],
  ['Endless summer', 'Bottomless coffee'],
  ['Banana', 'Bandana'],
  ['Coco Pops', 'Coco Cabana'],
  ['Slip Slop Slap', 'Reduce Reuse Recycle'],
  ['Rump', 'Humps']
]

const getRandomAnswerCount = () => getRandomIntInclusive(1, 10)

function fetchContent (state) {
  const ready = isContentReady(state)
  const questions = ready ? getQuestions(state) : initialQuestions
  const statistics = ready ? getStatistics(state) : questions.map(() => [getRandomAnswerCount(), getRandomAnswerCount()])
  const shuffledOrder = shuffle(range(questions.length))
  return Promise.resolve({
    questions: shuffledOrder.map(index => questions[index]),
    statistics: shuffledOrder.map(index => statistics[index])
  })
}

export default store => {
  const dispatchFetchContent = () => store.dispatch(contentActionCreator(fetchContent(store.getState())))
  setTimeout(dispatchFetchContent)

  return next => action => {
    switch (action.type) {
      case QUESTION_INDEX_CHANGED:
        if (action.payload.questionIndex === 0) {
          // if we're looping back, re-shuffle the content
          dispatchFetchContent()
        }
        break
      // no default
    }

    return next(action)
  }
}
