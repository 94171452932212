import range from 'lodash/range'
import React, { PureComponent } from 'react'
import * as PIXI from 'pixi.js'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { compose } from 'redux'
import TimelineMax from 'gsap/TimelineMax'
import { SteppedEase } from 'gsap/EasePack'
import { getQuestionIndex } from '../selectors'
import QuestionTitleBackground from './QuestionTitleBackground'

class TranslateableContainer extends PIXI.Container {
  _xPercent = 0
  _yPercent = 0

  get xPercent () {
    return this._xPercent
  }

  set xPercent (xPercent) {
    this._xPercent = xPercent
    this.x = this.width * xPercent / 100
  }

  get yPercent () {
    return this._yPercent
  }

  set yPercent (yPercent) {
    this._yPercent = yPercent
    this.y = this.height * yPercent / 100
  }
}

class Title extends PureComponent {
  componentDidMount () {
    const { pixi } = this.props
    const scene = this.scene = new PIXI.Container()

    const title = new PIXI.Text('YOU DECIDE', new PIXI.TextStyle({
      fontFamily: 'Righteous',
      fill: '#e319c5'
    }))
    title.anchor.set(0.5, 0.5)

    const bg = new PIXI.Graphics()
    const bgContainer = new TranslateableContainer()
    bgContainer.addChild(bg)

    const shadow1 = new PIXI.Graphics()
    shadow1.blendMode = PIXI.BLEND_MODES.HARD_LIGHT
    const shadow1Container = new TranslateableContainer()
    shadow1Container.addChild(shadow1)

    const shadow2 = new PIXI.Graphics()
    shadow2.blendMode = PIXI.BLEND_MODES.DARKEN
    const shadow2Container = new TranslateableContainer()
    shadow2Container.addChild(shadow2)

    scene.addChild(shadow1Container)
    scene.addChild(bgContainer)
    scene.addChild(title)
    scene.addChild(shadow2Container)

    pixi.stage.addChild(scene)
    this.updateLayout()

    const shadows = [shadow1Container, shadow2Container]
    const ease = SteppedEase.config(1)
    let coords = range(30).map(() => [
      (Math.random() - 0.5) * 1.5,
      (Math.random() - 0.5) * 5
    ])
    const t = this.timeline = new TimelineMax({ repeat: -1, useFrames: true, id: 'Question Title' })
    coords.forEach(([xPercent, yPercent], i) => {
      t.to(
        bgContainer,
        1, {
          xPercent,
          yPercent,
          ease
        },
        i)
    })
    coords = coords.map(() => [
      (Math.random() - 0.5) * 2,
      (Math.random() - 0.5) * 4
    ])
    coords.forEach(([xPercent, yPercent], i) => {
      t.to(
        shadows,
        1, {
          xPercent,
          yPercent,
          ease
        },
        i)
    })
  }

  componentDidUpdate () {
    this.updateLayout()
  }

  componentWillUnmount () {
    this.timeline.kill()
    const { pixi } = this.props
    const { scene } = this
    if (pixi && pixi.stage) {
      pixi.stage.addChild(scene)
    }
  }

  updateLayout () {
    const { pixi } = this.props
    const { scene } = this
    const [ shadow1Container, bgContainer, title, shadow2Container ] = scene.children
    const bg = bgContainer.children[0]
    const shadow1 = shadow1Container.children[0]
    const shadow2 = shadow2Container.children[0]

    const { width, height } = pixi.screen
    const centre = new PIXI.Point(width / 2, height / 2)
    const fontSize = width < height
      // in portrait mode, the font size should be relative to vmin
      ? Math.min(width, height) * 0.1
      // whereas in landscape mode, the font size should be relative to vmax/2
      : Math.max(width, height) * 0.0935

    title.style.fontSize = `${fontSize}px`
    title.position.copy(centre)

    const bgWidth = title.width + 1.5 * fontSize
    const bgHeight = title.height + 0.1 * fontSize
    const bgHalfWidth = bgWidth / 2
    const bgHalfHeight = bgHeight / 2
    bg
      .clear()
      .beginFill(0xe3d519)
      .drawRect(-bgHalfWidth, -bgHalfHeight, bgWidth, bgHeight)
      .endFill()
    bg.position.copy(centre)

    const shadowOrigin = new PIXI.Point(centre.x + 1.2 * fontSize, centre.y + 0.6 * fontSize)
    const shadowWidth = bgWidth - fontSize
    const shadowHalfWidth = shadowWidth / 2;
    [shadow1, shadow2].forEach(shadow => {
      shadow
        .clear()
        .beginFill(0x19d5e3)
        .drawRect(-shadowHalfWidth, -bgHalfHeight, shadowWidth, bgHeight)
        .endFill()
      shadow.position.copy(shadowOrigin)
    })
  }

  render () {
    return null
  }
}

const QuestionTitle = ({ classes }) => (
  <div className={classes.container}>
    <QuestionTitleBackground className={classes.background}>
      <Title />
    </QuestionTitleBackground>
  </div>
)

const mapStateToProps = (state) => ({
  questionIndex: getQuestionIndex(state)
})

export default compose(
  connect(mapStateToProps),

  injectSheet({
    container: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      cursor: 'none'
    },
    background: {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%'
    }
  })
)(QuestionTitle)
