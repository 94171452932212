import React from 'react'
import popcorn1 from './popcorn1.svg'
import popcorn2 from './popcorn2.svg'
import popcorn3 from './popcorn3.svg'
import popcorn4 from './popcorn4.svg'
import popcorn5 from './popcorn5.svg'
import popcorn6 from './popcorn6.svg'
import popcorn7 from './popcorn7.svg'
import Scatter from '../Scatter'

const Popcorn = ({ ...props }) => (
  <Scatter
    {...props}
    minimumStageScale={0.9}
    positionVariation={2}
    spacingWeight={0.9}
    jitter
    shapes={[
      { url: popcorn1, weight: 1, scale: 1 },
      { url: popcorn2, weight: 1, scale: 0.6 },
      { url: popcorn3, weight: 1, scale: 0.6 },
      { url: popcorn4, weight: 1, scale: 0.6 },
      { url: popcorn5, weight: 1, scale: 0.6 },
      { url: popcorn6, weight: 1, scale: 0.6 },
      { url: popcorn7, weight: 1, scale: 0.6 }
    ]}
  />
)

export default Popcorn
