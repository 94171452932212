import {
  ANSWER_SELECTED,
  FONTS_READY,
  FULLSCREEN_CHANGED,
  QUESTION_INDEX_CHANGED,
  READY_TO_ANSWER,
  STYLES_CHANGED
} from '../actions'

const initialState = {
  questionIndex: 0,
  answerIndex: -1,
  fullscreen: false,
  step: 'pre-question', // pre-question -> question -> percent
  styles: [
    {
      bg: 1,
      pattern: 1,
      fg: 1
    },
    {
      bg: 2,
      pattern: 2,
      fg: 2
    }
  ],
  fontsReady: false
}

const display = (state = initialState, action) => {
  switch (action.type) {
    case ANSWER_SELECTED:
      return {
        ...state,
        answerIndex: action.payload.answerIndex,
        step: 'percent'
      }
    case QUESTION_INDEX_CHANGED:
      return {
        ...state,
        questionIndex: action.payload.questionIndex,
        answerIndex: -1,
        step: 'pre-question'
      }
    case FULLSCREEN_CHANGED:
      return {
        ...state,
        fullscreen: action.payload.fullscreen
      }
    case READY_TO_ANSWER:
      return {
        ...state,
        step: 'question'
      }
    case STYLES_CHANGED:
      return {
        ...state,
        styles: action.payload.styles
      }
    case FONTS_READY:
      return {
        ...state,
        fontsReady: true
      }

    default:
      return state
  }
}

export default display
