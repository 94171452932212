import React from 'react'
import PatternBackground from './PatternBackground'
import Circles from './PatternBackground/Circles'
import Confetti from './PatternBackground/Confetti'
import Pasta from './PatternBackground/Pasta'
import Popcorn from './PatternBackground/Popcorn'

const backgroundColors = [
  0x93ccda,
  0x8dcb96,
  0xdad193,
  0xeac1a7
]

const AnswerBackground = ({ classes, className, backgroundIndex, patternIndex }) => {
  let Pattern
  switch (patternIndex) {
    case 1:
      Pattern = Circles
      break
    case 2:
      Pattern = Confetti
      break
    case 3:
      Pattern = Pasta
      break
    case 4:
      Pattern = Popcorn
      break
    // no default
  }
  const backgroundColor = backgroundColors[backgroundIndex - 1]
  return (
    <PatternBackground
      className={className}
      backgroundColor={backgroundColor}
      Pattern={Pattern}
    />
  )
}

export default AnswerBackground
