import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { compose } from 'redux'
import cn from 'classnames'
import { getQuestionAnswers, isPercentStep, isPreQuestionStep, isQuestionStep } from '../selectors'
import { answerSelectedCreator } from '../actions'
import Answer from './Answer'
import Percent from './Percent'
import QuestionTitle from './QuestionTitle'

const YouDecide = ({ answers, answersEnabled, classes, className, onAnswerClick, showPercent, showQuestionTitle }) => (
  <div className={cn(className, classes.container)}>
    {showQuestionTitle && <QuestionTitle />}
    <div className={classes.answers}>
      {answers.map(({ text, styles }, index) => (
        <Answer
          key={index}
          className={classes.question}
          enabled={answersEnabled}
          onClick={() => onAnswerClick(index)}
          styles={styles}
        >
          {text}
        </Answer>
      ))}
    </div>
    {showPercent && <Percent />}
  </div>
)

const mapStateToProps = (state) => ({
  answers: getQuestionAnswers(state),
  answersEnabled: isQuestionStep(state),
  showPercent: isPercentStep(state),
  showQuestionTitle: isPreQuestionStep(state)
})

const mapDispatchToProps = (dispatch) => ({
  onAnswerClick: index => dispatch(answerSelectedCreator(index))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  injectSheet({
    container: {
      textAlign: 'center',
      position: 'relative'
    },

    answers: {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'stretch',
      flexDirection: 'column',
      '@media (min-aspect-ratio: 1/1)': {
        flexDirection: 'row'
      }
    },

    question: {
      flex: '0 0 50%'
    }
  })
)(YouDecide)
