import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { compose } from 'redux'
import cn from 'classnames'
import { isFullScreen } from '../selectors'
import { ReactComponent as Icon } from './fullscreen.svg'
import { requestFullscreenCreator } from '../actions'

const FullscreenToggle = ({ classes, className, fullscreen, onClick }) => (
  fullscreen ? null : (
    <button className={cn(className, classes.toggle)} onClick={onClick}>
      <Icon className={classes.icon} />
    </button>
  )
)

const mapStateToProps = (state) => ({
  fullscreen: isFullScreen(state)
})

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(requestFullscreenCreator())
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),

  injectSheet({
    toggle: {
      display: 'block',
      color: '#3e45d3',
      fontSize: '5vmin',
      appearance: 'none',
      border: 0,
      backgroundColor: 'transparent',
      outline: 0,
      cursor: 'pointer',
      padding: 0
    },
    icon: {
      display: 'block',
      width: '1em',
      height: '1em',
      fill: 'currentColor'
    }
  })
)(FullscreenToggle)
