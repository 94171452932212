export const ANSWER_SELECTED = 'ANSWER_SELECTED'
export const NEXT_QUESTION = 'NEXT_QUESTION'
export const READY_TO_ANSWER = 'READY_TO_ANSWER'
export const QUESTION_INDEX_CHANGED = 'QUESTION_INDEX_CHANGED'
export const REQUEST_FULLSCREEN = 'REQUEST_FULLSCREEN'
export const FULLSCREEN_CHANGED = 'FULLSCREEN_CHANGED'
export const STYLES_CHANGED = 'STYLES_CHANGED'
export const FONTS_READY = 'FONTS_READY'

export const answerSelectedCreator = answerIndex => ({
  type: ANSWER_SELECTED,
  payload: {
    answerIndex
  }
})

export const nextQuestionCreator = () => ({
  type: NEXT_QUESTION,
  payload: {}
})

export const readyToAnswerCreator = () => ({
  type: READY_TO_ANSWER,
  payload: {}
})

export const questionIndexChangedCreator = questionIndex => ({
  type: QUESTION_INDEX_CHANGED,
  payload: {
    questionIndex
  }
})

export const requestFullscreenCreator = () => ({
  type: REQUEST_FULLSCREEN,
  payload: {}
})

export const fullscreenChangedCreator = fullscreen => ({
  type: FULLSCREEN_CHANGED,
  payload: {
    fullscreen
  }
})

export const stylesChangedCreator = styles => ({
  type: STYLES_CHANGED,
  payload: {
    styles
  }
})

export const fontsReadyCreator = () => ({
  type: FONTS_READY
})
