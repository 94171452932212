import React from 'react'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isContentReady, isFontsReady } from '../selectors'
import FullscreenToggle from './FullscreenToggle'
import YouDecide from './YouDecide'
import Loading from './Loading'
import komikaWoffUrl from '../fonts/KomikaAxis.woff'
import komikaWoff2Url from '../fonts/KomikaAxis.woff2'
import righteousWoffUrl from '../fonts/subset-Righteous-Regular.woff'
import righteousWoff2Url from '../fonts/subset-Righteous-Regular.woff2'

const App = ({ classes, ready }) => (
  <div className={classes.container}>
    {ready
      ? <YouDecide className={classes.youDecide} />
      : <Loading className={classes.loading} />}
    <FullscreenToggle className={classes.fullscreenToggle} />
  </div>
)

const mapStateToProps = (state) => ({
  ready: isContentReady(state) && isFontsReady(state)
})

export default compose(
  connect(mapStateToProps),

  injectSheet({
    '@global': {
      '@font-face': [
        {
          fontFamily: 'Komika Axis',
          src: `url('${komikaWoff2Url}') format('woff2'), url('${komikaWoffUrl}') format('woff')`,
          fontWeight: 'bold',
          fontStyle: 'normal'
        },
        {
          fontFamily: 'Righteous',
          src: `url('${righteousWoff2Url}') format('woff2'), url('${righteousWoffUrl}') format('woff')`,
          fontWeight: 'bold',
          fontStyle: 'normal'
        }
      ],
      '*, *::before, *::after': {
        boxSizing: 'border-box'
      },
      body: {
        margin: 0,
        padding: 0,
        fontFamily: '"Komika Axis", sans-serif',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        '-webkitFontSmoothing': 'antialiased',
        '-mozOsxFontSmoothing': 'grayscale'
      }
    },

    container: {
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#01074c'
    },

    youDecide: {
      width: '100%',
      height: '100%'
    },

    loading: {
      fontSize: 64,
      position: 'absolute',
      left: '50%',
      top: '50%',
      marginTop: -32,
      marginLeft: -32
    },

    fullscreenToggle: {
      position: 'absolute',
      right: '1vmin',
      bottom: '1vmin',
      zIndex: 1
    }
  })
)(App)
