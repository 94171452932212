import React from 'react'
import rigatoni from './rigatoni.svg'
import farfalle from './farfalle.svg'
import elbow from './elbow.svg'
import macarone from './macarone.svg'
import snowflake from './snowflake.svg'
import Scatter from '../Scatter'

const Pasta = ({ ...props }) => (
  <Scatter
    {...props}
    minimumStageScale={0.7}
    positionVariation={1.8}
    spacingWeight={0.9}
    jitter
    shapes={[
      { url: rigatoni, weight: 1, rotation: 0 },
      { url: rigatoni, weight: 1, rotation: 90 },
      { url: rigatoni, weight: 1, rotation: 110 },
      { url: rigatoni, weight: 1, rotation: 180 },
      { url: rigatoni, weight: 3, scale: 0.6, rotation: 0 },
      { url: rigatoni, weight: 3, scale: 0.6, rotation: 90 },
      { url: rigatoni, weight: 3, scale: 0.6, rotation: 110 },
      { url: rigatoni, weight: 3, scale: 0.6, rotation: 180 },
      { url: farfalle, weight: 20 },
      { url: farfalle, weight: 10, scale: 0.5 },
      { url: elbow, weight: 8, scale: 0.7 },
      { url: elbow, weight: 8, scale: 0.4 },
      { url: macarone, weight: 6 },
      { url: macarone, weight: 6, scale: 0.5 },
      { url: snowflake, weight: 9 }
    ]}
  />
)

export default Pasta
