import React from 'react'
import Scatter from '../Scatter'
import circle1 from './circle1.svg'
import circle2 from './circle2.svg'
import circle3 from './circle3.svg'
import circle4 from './circle4.svg'
import circle5 from './circle5.svg'
import circle6 from './circle6.svg'

const Circles = ({ ...props }) => (
  <Scatter
    {...props}
    minimumStageScale={0.8}
    positionVariation={1.2}
    spacingWeight={0.95}
    jitter
    shapes={[
      { url: circle1, weight: 1.5 },
      { url: circle2, weight: 7 },
      { url: circle3, weight: 6 },
      { url: circle4, weight: 3 },
      { url: circle5, weight: 2 },
      { url: circle6, weight: 1 }
    ]}
  />
)

export default Circles
