import React from 'react'
import injectSheet from 'react-jss'
import cn from 'classnames'
import AnswerBackground from './AnswerBackground'
import OutlinedText from './OutlinedText'

const Answer = ({ children, classes, className, enabled, onClick, styles }) => (
  <button
    className={cn(
      className,
      classes.question,
      classes[`fg${styles.fg}`]
    )}
    disabled={!enabled}
    onClick={onClick}
  >
    <AnswerBackground
      className={classes.background}
      backgroundIndex={styles.bg}
      patternIndex={styles.pattern}
    />
    {enabled && (
      <OutlinedText doubled>
        {children}
      </OutlinedText>
    )}
  </button>
)

export default injectSheet({
  question: {
    appearance: 'none',
    border: 0,
    backgroundColor: 'transparent',
    outline: 0,
    cursor: 'pointer',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1vmin 2vmin',
    tapHighlightColor: 'transparent',
    position: 'relative',
    // in portrait mode, the font size should be relative to vmin
    fontSize: '12vmin',
    '@media (min-aspect-ratio: 1/1)': {
      // in landscape mode, the font size should be relative to vmax/2
      fontSize: '6vmax'
    }
  },
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: -1
  },
  fg1: {
    color: '#ef662f'
  },
  fg2: {
    color: '#3e45d3'
  }
})(Answer)
