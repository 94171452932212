import React from 'react'
import PatternBackground from './PatternBackground'
import Pixels from './PatternBackground/Pixels'

const QuestionTitleBackground = ({ children, classes, className }) => (
  <PatternBackground
    className={className}
    backgroundColor={0x01074c}
    Pattern={Pixels}
  >
    {children}
  </PatternBackground>
)

export default QuestionTitleBackground
