import React from 'react'
import injectSheet from 'react-jss'
import cn from 'classnames'
import noise from './noise.png'

const OutlinedText = ({ children, classes, className, doubled }) => (
  <span className={cn(className, classes.text)}>
    <span className={classes.spacer}>{children}</span>
    {doubled && <span className={cn(classes.fancyText, classes.textOffset)}>
      <span>{children}</span>
      <span>{children}</span>
    </span>}
    <span className={classes.fancyText}>
      <span>{children}</span>
      <span>{children}</span>
    </span>
  </span>
)

export default injectSheet({
  text: {
    display: 'block',
    position: 'relative',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  spacer: {
    display: 'block',
    padding: '0.13em 0.2em',
    visibility: 'hidden'
  },
  fancyText: {
    top: '-0.14ex', // the font is a bit whack
    '&, &>span': {
      display: 'block',
      position: 'absolute',
      left: 0,
      width: '100%',
      height: '100%'
    },
    '&>span': {
      padding: '0.13em 0.2em',
      top: 0,
      '&:first-child': {
        textStroke: '0.16em #fff',
        maskImage: `url(${noise})`,
        maskRepeat: 'repeat'
      }
    }
  },
  textOffset: {
    left: '0.1em'
  }
})(OutlinedText)
